<i18n>
ru:
  minutesPeriodText: '{hour}:{start} — {hourEnd}:{end}'
ua:
  minutesPeriodText: '{hour}:{start} — {hourEnd}:{end}'
us:
  minutesPeriodText: '{hour}:{start} — {hourEnd}:{end}'
</i18n>

<template>
  <div class="v-delivery-time-buttons-popup">
    <div
      class="v-popup-head v-title v-delivery-time-buttons-title"
      v-html="title"
    />
    <div class="v-delivery-time-buttons-wrapper v-pm-shadow">
      <common-cards-flex-mesh
        :items="timeButtons"
        :max-items="2"
        data-test-id="delivery-time-popup"
      >
        <template #item="item: timeButton">
          <div
            class="v-delivery-time-single"
            :class="{ 'v-delivery-time-single-selected': item.value === clientTime }"
            @click="() => onClick(item.value)"
          >
            {{ item.text }}
          </div>
        </template>
      </common-cards-flex-mesh>
    </div>
  </div>
</template>

<script setup lang="ts">
import { MinutesSettingType } from '~api/consts'

import type { LimitOrderDate, TerminalDeliveryMinute, TerminalMinutesSetting } from '~types/clientStore'

type timeButton = {
  text: string
  value: number
}

const { title, enabledASAP, enabledScheduled } = defineProps<{
  title: string
  enabledASAP: boolean
  enabledScheduled: boolean
}>()

const appConfig = useAppConfig()
const clientStore = useClientStore()
const popupStore = usePopupStore()
const { eventOn } = useEmitter()
const { fromMillisInZone } = useDateTime()

const { translate, dateTime } = useI18nSanitized()

const scheduleDates = computed<LimitOrderDate[]>(() =>
  Object.values(clientStore?.TimeRestrictions.data?.Scheduled?.Schedule?.Dates ?? {})
)

const minutesSetting = computed<TerminalMinutesSetting | undefined>(() => {
  return clientStore?.TimeRestrictions.data?.Scheduled?.MinutesSetting
})

const timeButtons = ref<timeButton[]>([])
const clientTime = ref<number>(0)

function onClick(value: number): void {
  if (value === -1) {
    clientStore
      .updateOrderData({
        deliverRightNow: true,
        refreshState: true
      })
      .then(() => {
        popupStore.closePopup()
      })
  } else {
    clientStore
      .updateOrderData({
        deliverRightNow: false,
        deliveryTime: Math.floor(value / 1000),
        refreshState: true
      })
      .then(() => {
        popupStore.closePopup()
      })
  }
}

onMounted(() => {
  makeButtons()

  eventOn('v-time-refresh', () => {
    makeButtons()
  })
})

function makeButtons(): void {
  clientTime.value =
    (clientStore.ClientState?.data?.StateOrderData?.DeliverRightNow ?? true)
      ? -1
      : (clientStore.ClientState?.data?.StateOrderData?.DeliveryTime ?? 0) * 1000
  const timeButtonsLocal = [] as timeButton[]

  if (enabledASAP) {
    timeButtonsLocal.push({
      value: -1,
      text: translate('deliveryTimeRathloriel.deliveryASAPLabel')
    })
  }

  if (enabledScheduled) {
    let minutesSettingType = minutesSetting.value?.MinutesSettingType ?? MinutesSettingType.Default
    const minutes: TerminalDeliveryMinute[] = minutesSetting.value?.DeliveryMinutes ?? []
    if (minutes.length === 0) {
      minutesSettingType = MinutesSettingType.Default
    }

    for (const limitOrderDate of scheduleDates.value) {
      //for each date
      for (const period of limitOrderDate.RestrictedPeriods) {
        const periodStart = fromMillisInZone(period.Start, appConfig.RestaurantSettingsPreRun.GMT)
        const periodEnd = fromMillisInZone(period.End, appConfig.RestaurantSettingsPreRun.GMT)
        let runner = fromMillisInZone(
          Math.floor(period.Start / 1000) * 1000,
          appConfig.RestaurantSettingsPreRun.GMT
        ).set({
          second: 0
        })

        switch (minutesSettingType) {
          case MinutesSettingType.Value:
          case MinutesSettingType.Range: {
            while (runner < periodEnd) {
              for (const minuteSetting of minutes) {
                runner = runner.set({ minute: minuteSetting.PeriodStart })

                if (runner > periodStart) {
                  const ms = runner.toMillis()

                  let text = ''
                  const startMinute =
                    minuteSetting.PeriodStart < 10
                      ? `0${minuteSetting.PeriodStart}`
                      : minuteSetting.PeriodStart

                  const periodEndMinutes = minuteSetting.PeriodEnd ?? minuteSetting.PeriodStart

                  if (minutesSettingType === MinutesSettingType.Range) {
                    text = translate('deliveryTimeRathDinenPopup.minutesPeriodText', {
                      start: startMinute,
                      end: periodEndMinutes < 10 ? `0${periodEndMinutes}` : periodEndMinutes,
                      hour: runner.hour,
                      hourEnd:
                        minuteSetting.PeriodStart > periodEndMinutes ? runner.hour + 1 : runner.hour
                    })
                  } else {
                    text = `${runner.hour}:${startMinute}`
                  }

                  if (periodEnd.hour !== runner.hour || periodEnd.minute >= periodEndMinutes) {
                    timeButtonsLocal.push({
                      value: ms,
                      text: scheduleDates.value.length > 1 ? `${dateTime(ms, 'date')} ${text}` : text
                    })
                  }
                }
              }

              runner = runner.set({ minute: 0 }).plus({ hour: 1 })
            }

            break
          }
          case MinutesSettingType.Default: {
            // add minutes to start from minutes that evenly divisible by minutesStep
            // it could be a problem in case if period is smaller than minutesStep * 2 (don't do it)
            const minutesStepRemainder = runner.minute % clientStore.DeliveryTime.minutesStep
            const minutesToAddToFit =
              minutesStepRemainder === 0
                ? 0
                : clientStore.DeliveryTime.minutesStep - minutesStepRemainder
            runner = runner.plus({ minutes: minutesToAddToFit }).startOf('minute')

            while (runner < periodEnd) {
              const ms = runner.toMillis()
              timeButtonsLocal.push({
                value: ms,
                text: dateTime(ms, scheduleDates.value.length > 1 ? 'orderTime' : 'time')
              })
              runner = runner.plus({ minutes: clientStore.DeliveryTime.minutesStep })
            }
            break
          }
        }
      }
    }
  }

  timeButtons.value = timeButtonsLocal
}
</script>

<style lang="scss">
@use '~/assets/variables';

.v-delivery-time-buttons-popup {
  .v-delivery-time-buttons-wrapper {
    max-height: 60vh;
    overflow-y: auto;
  }

  .v-delivery-time-buttons-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .v-delivery-time-single {
    padding: 14px;
    cursor: pointer;
    font-size: variables.$TextSizeMain;
    font-variant-numeric: tabular-nums;

    &-selected {
      border: 1px solid variables.$PrimaryBackgroundColor;
      color: variables.$PrimaryBackgroundColor;
      border-radius: variables.$BorderRadiusInput;
    }
  }
}
</style>
